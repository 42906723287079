<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting filter_wpr">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form @submit="handleSubmitForm" ref="forms-setting-form" v-slot="{ errors }" class="forms-setting-form" >
                    <div class="content_wpr mb-0">
                        <div class="section_content">
                            <div class="acton_header mb-0">
                                <div class="section_header">
                                    <h2>Includes</h2>
                                </div>
                            </div>
                            <div class="setting_wpr mb-5">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Group Name</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                            <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="ex: New Group" rules="required" />
                                        </div>
                                        <ErrorMessage name="name" class="text-danger" />
                                    </div>
                                </div>
                                <label for="inmatch_any" class="switch_option capsule_btn">
                                    <h5>Matching Any<span>Select one or a few filters where ANY can match the criteria</span></h5>
                                    <input type="radio" id="inmatch_any" value="or" v-model="form.param" hidden>
                                    <div><span></span></div>
                                </label>
                                <label for="inmatch_all" class="switch_option capsule_btn">
                                    <h5>Matching All<span>Select one or a few filters where ALL must match the criteria.</span></h5>
                                    <input type="radio" id="inmatch_all" value="and" v-model="form.param" hidden>
                                    <div><span></span></div>
                                </label>
                                <div class="condition_wpr" v-for="(includeCondition, c) in includeConditions" :key="c">
                                    <div class="edit_section mt-3 mb-3">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Select Filter</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="includeCondition.type"
                                                        :options="filterList"
                                                        value-prop="value"
                                                        label="label"
                                                        @select="checkIncludeFilterExist(c)"
                                                        placeholder="Select One.."
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp" v-if="includeCondition.type == 'tags'">
                                            <div class="group_item">
                                                <label class="input_label">Select Tags</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors[`include-tag-${c}`] }" >
                                                    <Field autocomplete="off" :name="`include-tag-${c}`" v-model="includeCondition.ids" rules="required"  label="tag">
                                                        <multiselect
                                                            v-model="includeCondition.ids"
                                                            :groups="true"
                                                            :searchable="true"
                                                            :options="tagsGroup"
                                                            value-prop="id"
                                                            label="name"
                                                            placeholder="Select tag"
                                                            :group-select="false"
                                                            group-label="type"
                                                            group-options="tags"
                                                            :group-hide-empty="true"
                                                            mode="tags"
                                                            :close-on-select="false"
                                                            @select="checkIsSelected($event, { index: c, section: 'includeConditions', tagType: 'not_tags' })"
                                                        >
                                                            <template v-slot:grouplabel="{ group }">
                                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <span>{{ option.name }}</span>
                                                            </template>
                                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                  {{ option.name }}
                                                                  <span
                                                                    v-if="!disabled"
                                                                    class="multiselect-tag-remove"
                                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                                  >
                                                                      <span class="multiselect-tag-remove-icon"></span>
                                                                  </span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage :name="`include-tag-${c}`" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp" v-if="includeCondition.type == 'not_tags'">
                                            <div class="group_item">
                                                <label class="input_label">Select Tags</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors[`include-not-tag-${c}`] }" >
                                                    <Field autocomplete="off" :name="`include-not-tag-${c}`" v-model="includeCondition.ids" rules="required"  label="tag">
                                                        <multiselect
                                                            v-model="includeCondition.ids"
                                                            :groups="true"
                                                            :searchable="true"
                                                            :options="tagsGroup"
                                                            value-prop="id"
                                                            label="name"
                                                            placeholder="Select tag"
                                                            :group-select="false"
                                                            group-label="type"
                                                            group-options="tags"
                                                            :group-hide-empty="true"
                                                            mode="tags"
                                                            :close-on-select="false"
                                                            @select="checkIsSelected($event, { index: c, section: 'includeConditions', tagType: 'tags' })"
                                                        >
                                                            <template v-slot:grouplabel="{ group }">
                                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <span>{{ option.name }}</span>
                                                            </template>
                                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                  {{ option.name }}
                                                                  <span
                                                                    v-if="!disabled"
                                                                    class="multiselect-tag-remove"
                                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                                  >
                                                                      <span class="multiselect-tag-remove-icon"></span>
                                                                  </span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage :name="`include-not-tag-${c}`" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp" v-if="includeCondition.type == 'segments'">
                                            <div class="group_item">
                                                <label class="input_label">Select Groups</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors[`include-label-${c}`] }">
                                                    <Field autocomplete="off" :name="`include-label-${c}`" v-model="includeCondition.ids" rules="required"  label="group">
                                                        <multiselect
                                                            v-model="includeCondition.ids"
                                                            :searchable="true"
                                                            :options="groups"
                                                            value-prop="id"
                                                            label="name"
                                                            placeholder="Select group"
                                                            mode="tags"
                                                            :close-on-select="false"
                                                        >
                                                        <template v-slot:option="{ option }">
                                                            <span>{{ option.name }}</span>
                                                        </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage :name="`include-label-${c}`" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp" v-if="includeCondition.type == 'tabs'">
                                            <div class="group_item">
                                                <label class="input_label">Select segments</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors[`include-tab-${c}`] }">
                                                    <Field autocomplete="off" :name="`include-tab-${c}`" v-model="includeCondition.ids" rules="required"  label="tab">
                                                        <multiselect
                                                        v-model="includeCondition.ids"
                                                        :groups="true"
                                                        :searchable="true"
                                                        :options="statusesGroup"
                                                        valueProp="value"
                                                        label="title"
                                                        placeholder="Select segment"
                                                        :group-select="false"
                                                        group-label="type"
                                                        group-options="items"
                                                        :group-hide-empty="true"
                                                        mode="tags"
                                                        :close-on-select="false"
                                                        >
                                                            <template v-slot:grouplabel="{ group }">
                                                                <span class="status-tabs-header">{{ group.type }}</span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <span>{{ option.title }}</span>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage :name="`include-tab-${c}`" class="text-danger" />
                                            </div>
                                        </div>
                                        <button v-show="includeConditions && includeConditions[0] && includeConditions[0]['type']" type="button" class="remove_btn pointer" @click="includeDelFilter($event, c)"><i class="fas fa-trash-alt"></i></button>
                                    </div>
                                    <div class="divider"><span>{{ form.param == 'or' ? 'Or' : 'And' }}</span></div>
                                </div>
                                <button type="button" class="add_btn pointer" @click="includeAddFilter()"><i class="fas fa-plus"></i>Add Another Condition</button>
                            </div>
                            <label for="include_other" class="switch_option capsule_btn mb-3">
                                <h5>Other Filter<span>Filter by Name, Date, Temperature, Email &amp; Status</span></h5>
                                <input type="checkbox" id="include_other" v-model="form.include_has_other_condition" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <div class="edit_section" v-show="form.include_has_other_condition == 1">
                                <search-filter ref="include-search-filter" :statuses-group="statusesGroup" />
                            </div>
                        </div>
                        <div class="section_content">
                            <div class="acton_header mb-0">
                                <div class="section_header">
                                    <h2>Excludes</h2>
                                </div>
                            </div>
                            <div class="setting_wpr mb-5">
                                <label for="exmatch_any" class="switch_option capsule_btn">
                                    <h5>Matching Any<span>Select one or a few filters where ANY can match the criteria</span></h5>
                                    <input type="radio" id="exmatch_any" value="or" v-model="form.exclude_params" hidden>
                                    <div><span></span></div>
                                </label>
                                <label for="exmatch_all" class="switch_option capsule_btn">
                                    <h5>Matching All<span>Select one or a few filters where ALL must match the criteria.</span></h5>
                                    <input type="radio" id="exmatch_all" value="and" v-model="form.exclude_params" hidden>
                                    <div><span></span></div>
                                </label>
                                <div class="condition_wpr" v-for="(excludeCondition, e) in excludeConditions" :key="e">
                                    <div class="edit_section mt-3 mb-3">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Select Filter</label>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="excludeCondition.type"
                                                        :options="filterList"
                                                        value-prop="value"
                                                        label="label"
                                                        @select="checkExcludeFilterExist(e)"
                                                        placeholder="Select One.."
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp" v-if="excludeCondition.type == 'tags'">
                                            <div class="group_item">
                                                <label class="input_label">Select Tags</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors[`exclude-tag-${e}`] }">
                                                    <Field autocomplete="off" :name="`exclude-tag-${e}`" v-model="excludeCondition.ids" rules="required"  label="tag">
                                                        <multiselect
                                                        v-model="excludeCondition.ids"
                                                        :groups="true"
                                                        :searchable="true"
                                                        :options="tagsGroup"
                                                        value-prop="id"
                                                        label="name"
                                                        placeholder="Select tag"
                                                        :group-select="false"
                                                        group-label="type"
                                                        group-options="tags"
                                                        :group-hide-empty="true"
                                                        mode="tags"
                                                        :close-on-select="false"
                                                        @select="checkIsSelected($event, { index: e, section: 'excludeConditions', tagType: 'not_tags' })"
                                                        >
                                                            <template v-slot:grouplabel="{ group }">
                                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <span>{{ option.name }}</span>
                                                            </template>
                                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                  {{ option.name }}
                                                                  <span
                                                                    v-if="!disabled"
                                                                    class="multiselect-tag-remove"
                                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                                  >
                                                                      <span class="multiselect-tag-remove-icon"></span>
                                                                  </span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage :name="`exclude-tag-${e}`" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp" v-if="excludeCondition.type == 'not_tags'">
                                            <div class="group_item">
                                                <label class="input_label">Select Tags</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors[`exclude-not-tag-${e}`] }" >
                                                    <Field autocomplete="off" :name="`exclude-not-tag-${e}`" v-model="excludeCondition.ids" rules="required"  label="tag">
                                                        <multiselect
                                                            v-model="excludeCondition.ids"
                                                            :groups="true"
                                                            :searchable="true"
                                                            :options="tagsGroup"
                                                            value-prop="id"
                                                            label="name"
                                                            placeholder="Select tag"
                                                            :group-select="false"
                                                            group-label="type"
                                                            group-options="tags"
                                                            :group-hide-empty="true"
                                                            mode="tags"
                                                            :close-on-select="false"
                                                            @select="checkIsSelected($event, { index: e, section: 'excludeConditions', tagType: 'tags' })"
                                                        >
                                                            <template v-slot:grouplabel="{ group }">
                                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <span>{{ option.name }}</span>
                                                            </template>
                                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                  {{ option.name }}
                                                                  <span
                                                                    v-if="!disabled"
                                                                    class="multiselect-tag-remove"
                                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                                  >
                                                                      <span class="multiselect-tag-remove-icon"></span>
                                                                  </span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage :name="`exclude-not-tag-${e}`" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp" v-if="excludeCondition.type == 'segments'">
                                            <div class="group_item">
                                                <label class="input_label">Select Groups</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors[`exclude-label-${e}`] }">
                                                    <Field autocomplete="off" :name="`exclude-label-${e}`" v-model="excludeCondition.ids" rules="required"  label="group">
                                                        <multiselect
                                                            v-model="excludeCondition.ids"
                                                            :searchable="true"
                                                            :options="groups"
                                                            value-prop="id"
                                                            label="name"
                                                            placeholder="Select group"
                                                            mode="tags"
                                                            :close-on-select="false"
                                                        >
                                                        <template v-slot:option="{ option }">
                                                            <span>{{ option.name }}</span>
                                                        </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage :name="`exclude-label-${e}`" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp" v-if="excludeCondition.type == 'tabs'">
                                            <div class="group_item">
                                                <label class="input_label">Select segments</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors[`exclude-tab-${e}`] }">
                                                    <Field autocomplete="off" :name="`exclude-tab-${e}`" v-model="excludeCondition.ids" rules="required"  label="tab">
                                                        <multiselect
                                                        v-model="excludeCondition.ids"
                                                        :groups="true"
                                                        :searchable="true"
                                                        :options="statusesGroup"
                                                        valueProp="value"
                                                        label="title"
                                                        placeholder="Select segment"
                                                        :group-select="false"
                                                        group-label="type"
                                                        group-options="items"
                                                        :group-hide-empty="true"
                                                        mode="tags"
                                                        :close-on-select="false"
                                                        >
                                                            <template v-slot:grouplabel="{ group }">
                                                                <span class="status-tabs-header">{{ group.type }}</span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <span>{{ option.title }}</span>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage :name="`exclude-tab-${e}`" class="text-danger" />
                                            </div>
                                        </div>
                                        <button v-show="excludeConditions && excludeConditions[0] && excludeConditions[0]['type']" type="button" class="remove_btn pointer" @click="excludeDelFilter($event, e)"><i class="fas fa-trash-alt"></i></button>
                                    </div>
                                    <div class="divider"><span>{{form.exclude_params == 'or' ? 'Or' : 'And'}}</span></div>
                                </div>
                                <button type="button" class="add_btn pointer" @click="excludeAddFilter"><i class="fas fa-plus"></i>Add Another Condition</button>
                            </div>
                            <label for="exclude_other" class="switch_option capsule_btn mb-3">
                                <h5>Other Filter<span>Filter by Name, Date, Temperature, Email &amp; Status</span></h5>
                                <input type="checkbox" id="exclude_other" v-model="form.exclude_has_other_condition" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <div class="edit_section" v-show="form.exclude_has_other_condition == 1">
                                <search-filter ref="exclude-search-filter" :statuses-group="statusesGroup" />
                            </div>
                        </div>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                        <button v-if="isEdit" :disabled="loader" type="button" class="btn danger_btn" @click="handleDeleteGroup()">Delete</button>
                        <button :disabled="loader" type="submit" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    const SearchFilter = defineAsyncComponent(() => import('@/pages/group/components/SearchFilter'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Edit Group',

        data () {
            return {
                form: {
                    name: '',
                    param: 'or',
                    condition: [],
                    is_new: 1,
                    exclude_params: 'or',
                    exclude_condition: [],
                    exclude_has_other_condition: 0,
                    exclude_other_condition: {},
                    include_has_other_condition: 0,
                    include_other_condition: {},
                },
                includeTabCount: 1,
                excludeTabCount: 1,
                includeConditions: [
                    { type: '', ids: [] },
                ],
                excludeConditions: [
                    { type: '', ids: [] },
                ],
                filterList: [
                    { value: 'all', label: 'All subscibers' },
                    { value: 'tags', label: 'Has tag' },
                    { value: 'not_tags', label: 'Does not have tag' },
                    { value: 'segments', label: 'Is in group' },
                    { value: 'tabs', label: 'Is in tab' },
                ],
                statusesGroup: [],
            }
        },

        props: {
            modelValue: Boolean,
            selectedGroup: Object,
            isEdit: {
                type: Boolean,
                default: false,
            }
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
            SearchFilter,
        },

        computed: mapState({
            statusesGroupOriginal: state => state.contactModule.statusesGroup,
            groups: state => state.groupModule.userGroups,
            tagsGroup: state => state.tagModule.tagsGroup,
            loader: state => state.groupModule.groupComponentLoader,
        }),

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();
                    vm.filterStatusTabs(vm.statusesGroupOriginal);
                }
            },

            statusesGroupOriginal (tabs) {
                const vm = this;

                vm.filterStatusTabs(tabs);
            },
        },

        mounted () {
            const vm = this;

            if (vm.groups.length == 0) {
                vm.getGroups();
            }

            if (vm.statusesGroupOriginal && vm.statusesGroupOriginal.length == 0) {
                vm.getStatuses();
            }

            if (vm.tagsGroup.length == 0) {
                vm.getTags();
            }

            vm.filterStatusTabs(vm.statusesGroupOriginal);
        },

        methods: {
            ...mapActions({
                createGroup: 'groupModule/createGroup',
                getUserGroups: 'groupModule/getUserGroups',
                editGroup: 'groupModule/editGroup',
                deleteGroup: 'groupModule/deleteGroup',
                getGroups: 'groupModule/getGroups',
                getStatuses: 'contactModule/getStatuses',
                getTags: 'tagModule/getTags',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            includeAddFilter () {
                const vm = this;

                if (vm.includeTabCount >= vm.filterList.length) {
                    Toastr.error(`All filters are already added! This can't be added!`)
                } else {
                    vm.includeConditions.splice((vm.includeConditions.length + 1), 0, { type: '', ids: [] });
                    vm.includeTabCount++;
                }

            },

            excludeAddFilter () {
                const vm = this;

                if (vm.excludeTabCount >= vm.filterList.length) {
                    Toastr.error(`All filters are already added! This can't be added!`)
                } else {
                    vm.excludeConditions.splice((vm.excludeConditions.length + 1), 0, { type: '', ids: [] });
                    vm.excludeTabCount++;
                }
            },

            includeDelFilter (ev, index) {
                ev.stopPropagation();

                const vm = this;

                if (vm.includeConditions.length == 1) {
                    vm.includeConditions.splice(index, 1, { type: '', ids: [] });
                } else {
                    vm.includeConditions.splice(index, 1);
                    vm.includeTabCount--;
                }
            },

            excludeDelFilter(ev, index) {
                ev.stopPropagation();

                const vm = this;

                if (vm.excludeConditions.length == 1) {
                    vm.excludeConditions.splice(index, 1, { type: '', ids: [] });
                } else {
                    vm.excludeConditions.splice(index, 1);
                    vm.excludeTabCount--;
                }
            },

            checkIncludeFilterExist (index) {
                const vm     = this;
                let isExist  = false;
                const filter = vm.includeConditions[index]['type'];

                vm.includeConditions.forEach((item, i) => {
                    if (i != index && item['type'] && item['type'] == filter ) {
                        return isExist = true;
                    }
                });

                if (isExist) {
                    vm.includeConditions[index] = { type: '', ids: [] };
                    Toastr.error(`This is already selected! This can't be selected!`)
                } else {
                    vm.includeConditions[index]['ids'] = [];
                }
            },

            checkExcludeFilterExist (index) {
                const vm     = this;
                let isExist  = false;
                const filter = vm.excludeConditions[index]['type'];

                vm.excludeConditions.forEach((item, i) => {
                    if (i != index && item['type'] && item['type'] == filter ) {
                        return isExist = true;
                    }
                });

                if (isExist) {
                    vm.excludeConditions[index] = { type: '', ids: [] };
                    Toastr.error(`This is already selected! This can't be selected!`)
                } else {
                    vm.excludeConditions[index]['ids'] = [];
                }
            },

            handleSubmitForm (form, { setFieldError }) {
                const vm = this;

                const includeFilterComponent    = vm.$refs['include-search-filter'];
                const excludeFilterComponent    = vm.$refs['exclude-search-filter'];


                vm.form.include_other_condition = includeFilterComponent.filters ? includeFilterComponent.filters : vm.include_other_condition;
                vm.form.exclude_other_condition = excludeFilterComponent.filters ? excludeFilterComponent.filters : vm.exclude_other_condition;
                vm.form.condition               = vm.cleanConditions(JSON.parse(JSON.stringify(vm.includeConditions)));
                vm.form.exclude_condition       = vm.cleanConditions(JSON.parse(JSON.stringify(vm.excludeConditions)));
                vm.form.setFieldError           = setFieldError;

                if (!vm.isEdit) {
                    vm.createGroup(vm.form).then((result) => {
                        if (result) {
                            vm.$parent.contactWatcher++;
                            vm.getUserGroups();
                            vm.closeModal()
                        }
                    });
                } else {
                    vm.editGroup(vm.form).then((result) => {
                        if (result) {
                            vm.$parent.contactWatcher++;
                            vm.getUserGroups();
                            vm.closeModal()
                        }
                    });
                }
            },

            cleanConditions(conditions) {
                conditions.forEach((con, i) => {
                    if (con.type == null || con.type == '') {
                        conditions[i] = [];
                    }
                });

                if (conditions.length < 5) {
                    for (var i = 0; i < (8 - conditions.length); i++) {
                        conditions.push([]);
                    }
                }

                return conditions;
            },

            resetForm () {
                const vm = this;

                if (vm.isEdit) {
                    vm.form.id                          = vm.selectedGroup.id ? vm.selectedGroup.id  : '';
                    vm.form.name                        = vm.selectedGroup.name ? vm.selectedGroup.name  : '';
                    vm.form.param                       = vm.selectedGroup.param ? vm.selectedGroup.param  : 'or';
                    vm.form.condition                   = [];
                    vm.form.exclude_params              = vm.selectedGroup.exclude_params ? vm.selectedGroup.exclude_params  : 'or';
                    vm.form.exclude_condition           = [];
                    vm.form.exclude_has_other_condition = vm.selectedGroup.exclude_has_other_condition ? vm.selectedGroup.exclude_has_other_condition : 0;
                    vm.form.include_has_other_condition = vm.selectedGroup.include_has_other_condition ? vm.selectedGroup.include_has_other_condition : 0;
                    vm.form.exclude_other_condition     = vm.selectedGroup.exclude_other_condition ? JSON.parse(vm.selectedGroup.exclude_other_condition) : {};
                    vm.form.include_other_condition     = vm.selectedGroup.include_other_condition ? JSON.parse(vm.selectedGroup.include_other_condition) : {};
                    vm.includeConditions                = vm.selectedGroup.condition ? vm.filterCondition(JSON.parse(vm.selectedGroup.condition)) : [ { type: '', ids: [] } ];
                    vm.excludeConditions                = vm.selectedGroup.exclude_condition ? vm.filterCondition(JSON.parse(vm.selectedGroup.exclude_condition)) : [ { type: '', ids: [] } ];
                    vm.includeTabCount                  = 1;
                    vm.excludeTabCount                  = 1;

                    setTimeout(function () {
                        let includeFilterComponent     = vm.$refs['include-search-filter'];
                        let excludeFilterComponent     = vm.$refs['exclude-search-filter'];

                        includeFilterComponent.filters = Object.assign(includeFilterComponent.filters, vm.form.include_other_condition);
                        excludeFilterComponent.filters = Object.assign(excludeFilterComponent.filters, vm.form.exclude_other_condition);
                    }, 100);
                } else {
                    vm.resetFormOriginal();
                }
            },

            resetFormOriginal () {
                const vm = this;

                vm.includeTabCount   = 1;
                vm.excludeTabCount   = 1;
                vm.includeConditions = [ { type: '', ids: [] } ];
                vm.excludeConditions = [ { type: '', ids: [] } ];

                vm.form = {
                    name: '',
                    params: 'or',
                    condition: [],
                    is_new: 1,
                    exclude_params: 'or',
                    exclude_condition: [],
                    exclude_has_other_condition: 0,
                    exclude_other_condition: {},
                    include_has_other_condition: 0,
                    include_other_condition: {},
                };
            },

            handleDeleteGroup () {
                const vm      = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this group`, 'Delete');

                options.preConfirm = function () {
                    const form = { id: vm.selectedGroup.id, name: vm.selectedGroup.name };
                    return vm.deleteGroup(form).then((result) => {
                        if (result) {
                            if (vm.$parent.$parent && vm.$parent.$parent.group_id) {
                                vm.$parent.$parent.group_id = '';
                            } else {
                                vm.$parent.group_id = '';
                            }

                            vm.getUserGroups();
                            vm.closeModal();
                        }
                    });
                };

                Swal.fire(options);
            },

            filterCondition (condition) {
                let newCondition = condition.filter(obj => { return obj.length !== 0 ? obj : null})

                return newCondition.length ? newCondition : [ { type: '', ids: [] } ];
            },

            checkIsSelected (id, params) {
                const vm     = this;
                let isExist  = false;

                vm[params.section].forEach((item, i) => {
                    if ( item['type'] == params.tagType && item['ids'].length ) {
                        return isExist = item['ids'].includes(id) ? true : false;
                    }
                });

                if (isExist) {
                    vm[params.section][params.index]['ids'].splice(vm[params.section][params.index]['ids'].length - 1, 1);
                    Toastr.error(`This tag is already selected in ${ params.tagType == 'not_tags' ? 'Does not have tag' : 'Has tag' } ! This can't be selected!`)
                }
            },

            filterStatusTabs (tabs) {
                const vm = this;
                let statuses = JSON.parse(JSON.stringify(tabs));

                statuses.forEach((tabs, index) => {
                    if (tabs.type == 'lost') {
                        statuses[index].items = statuses[index].items.filter((item) => {
                            return item.value != '' && item.value != 'all';
                        });
                    }
                });

                vm.statusesGroup = statuses;
            }
        }
    }
</script>

<style scoped>
    .filter_wpr .content_wpr {
        flex: 1;
    }

    .global_setting .tabs_content .section_content {
        padding: 30px 50px;
    }

    .global_setting .tabs_content .section_content:last-of-type {
        border-left: 1px solid #e9e9e9;
    }

    .global_setting .tabs_content form > .action_wpr {
        padding: 15px 40px;
    }

    .forms-setting-form  {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .filter_wpr .divider, :deep(.filter_wpr .divider) {
        padding: 10px 0;
        position: relative;
    }

    .filter_wpr .divider:before, :deep(.filter_wpr .divider:before) {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        right: 0;
        border-top: 1px solid #e9e9e9;
    }

    .filter_wpr .divider span, :deep(.filter_wpr .divider span) {
        background: #fff;
        position: relative;
        z-index: 1;
        padding-right: 10px;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #5a5a5a;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .condition_wpr:last-of-type .divider {
        display: none;
    }

    .condition_wpr .remove_btn {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        position: absolute;
        right: 10px;
        opacity: 0;
        top: 7px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .condition_wpr .edit_section:hover .remove_btn {
        opacity: 1;
    }

    .filter_wpr .add_btn {
        margin: 5px 0 5px auto;
        font-size: 13px;
        line-height: 16px;
    }

    .filter_wpr .add_btn i {
        font-size: 11px;
    }

    .filter_wpr .edit_section {
        padding: 15px 30px;
        position: relative;
    }

    @media(max-width: 767px){
        .global_setting .tabs_content .section_content{
            width: 100%;
        }
        .global_setting .tabs_content .section_content:last-of-type {
            border-left: 0;
            border-top: 1px solid #e9e9e9;
        }
    }
    @media(max-width: 499px){
        .global_setting .tabs_content .section_content{
            padding: 30px 35px;
        }
    }
</style>
